
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.head{
    background-color: black;
}
/****************** About-page *******************/
.Prod-head-title{
  
  color: #D0343C ;
}
.govt-Client-img{
  height: min-content;

}
.abt-page{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.abt-breadcumbs{
    font-size: larger;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.abt-image{
  border-radius: 3%;
  float: left;
  margin-right: 45px;
  margin-bottom: 45px;
  box-shadow: 0 15px 40px 0  #489bee;  
}
.abtpage-title{
  color: #33A8D9;
}
.Abt-card{
  width: auto;
  height: auto;
  background: #d7d4d4;
 
}

.abt-card-title{
  color: #33A8D9;
}
/*******************Contact-us Page*****************************/
.contact-head{
font-weight: bold;
color:#489bee;
}
.contact-map{
  max-height: 5000px;
}
.contact-back{
    margin: 0;
    padding: 0;
}
.content-info{
font-weight: bold;
color: rgb(27, 2, 36);
}
p{
  text-align: justify;
}
.contact-banner-head{
    position: absolute;
    top: 55%;
    left: 30%;
    color: white;
    
}
.contact:hover{
background-color: #D0343C;
}
.aboutpage-head , .barcode-page-head {
    position: relative;

    transform: translate(-70%, -50%);
    color: white;
    
}

.solution-page-head{
  position: relative;
  top: 50%;
  left: 50%;
  
  color: white;
 
  
}
@media only screen and (max-width: 760px) {

}

.responsive-iframe{
    position: absolute;
    width: 85%;
    height: 60%;
}


/********************* Footer-Page **************/

.reach-icon{
    font-size: 2rem;
}
.reachus{
   color: white; 
}
.copy-right-bg{
background-color: #33A8D9;
;
color: black;
}




.social-iconfb {
    border-radius: 10%;
    background-color:rgb(6, 31, 143);
    padding: 1px;
    font-size: 3rem;
       
}
.social-iconLn{
  border-radius: 10%;
  background-color:rgb(24, 59, 216);
  padding: 1px;
  font-size: 3rem;
     
}
.social-icontw {
  border-radius: 10%;
  background-color:rgb(63, 183, 238);
  padding: 1px;
  font-size: 3rem;

     
}

.social-icongp{
  border-radius: 10%;
  background-color:rgb(204, 30, 30);
  padding: 1px;
  font-size: 3rem;

     
}
.social-iconIn{
  border-radius: 25%;
  background-color:rgb(171, 12, 131);
  padding: 1px;
  font-size: 3rem;
      
}


.reach-mail{
 color: white;
    
}
.section-title{
    position: relative;
    color: whitesmoke;
    font-size: large;
    display: inline-block;
    
}

.section-title::after{
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: #D0343C ;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out
}
.section-title:hover{
    color: #33A8D9 ;
}
.section-title:hover::after{
    
    transform: scaleX(1);
    transform-origin: bottom left;
}
.footer-logo-name{
    color: #33A8D9;
    font-size: 24px;
}

.footer-text{
  color: gray;
}
.footer-text1{
  color: white;
}
.footer-text:hover{
  color: #eee60c;
}
/************************** Slider component **********************/
.btn-quote{
  background-color: #D0343C;

}
.btn-quote:hover{
    background:  #D0343C;
    border: 1px solid #33A8D9;
}
.footer-btn:hover{
  background:  #D0343C;
  border: 1px solid #33A8D9;
}
.btn-abtus{
  background-color: #33A8D9;
}
.btn-abtus:hover{
  background: #33A8D9;
  border: 1px solid #D0343C;
}

.slider-head{
    font-size: 3rem;
}
.slider-head:hover{

color: #33A8D9 ;
text-shadow: 2px 2px 1.6px #D0343C;

}

.Slider2-head{
  transform: translate( 0, 10%);
  font-size: 2rem;
  
}
.Slider2-head:hover{
  color:#33A8D9 ;
  text-shadow: 1px 1px  white;
}
/****************************Home page **************/
.home-logo-application{
  
    max-width: 10%;
    max-height: 10%;
    position: absolute;
    opacity: .1;
    z-index: -1;
}


.home-logo{
   
    position: absolute;
    opacity: .1;
    z-index: -1;
}

.home-head{
    margin-top: 50px;
    text-align: center;
    padding-bottom: 10px;
    color:#D0343C ;
}

.home-head:hover{
    color: #33A8D9;
    text-shadow: 2px 2px 1px white;
}

.rfidworks{
  position: relative;
  width: 90%;
  height: 100%;
  box-shadow: 0 10px 30px 0 rgba(1, 28, 33, 0.75);
 
}
.rfidworks-head{
  color:#33A8D9;
  letter-spacing: 1px;
  transition: all .3s ease;
  
}
.rfidworks .line-wrap .line{
  width: 522px;
  height: 100%;
  border: 4px solid #35737f;
  z-index: 1;
}
.how-rfidworks{
  color:  #666;
}
.how-rfidworks span{
  font-weight: bold;
  font-size: large;
}
.rfidworks-icon{
  color: #D0343C;
}
.cat-head{
    color:#33A8D9;
    text-align: center;
  
}
.cat-head:hover{

letter-spacing: 3px;
text-shadow: 2px 2px 1px #D0343C;
transition: all .9s ease;

}
.line{
    display: block;
    height: 2px;
    background: #33A8D9;
    width: 60px;
    margin-right: 10px;
}
.home-abt-head{
    font-size: 18.5px;
    color: #33A8D9;
}
.home-hot-sale{
  z-index: -1;
 color: #33A8D9;
}

.home-pop-prod{
  z-index: -1;
  color:#D0343C ;
}
.abt-head:hover{
 color: #33A8D9;
 text-shadow: 2px 2px 1px #D0343C;
}
.abt-btn{
    padding: 0 25px;
    color: #fff;
    font-size: 18px;
    height: 50px;
    background: #D0343C;
    box-shadow: 0 14px 51px 0 #D0343C;
    border-radius: 25px;
}
.abt-btn:hover{
    background: #33A8D9;
    color: #fff;
}



.abt-txt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.abt-contact-btn{
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.contact-btn:hover{
    background: #33A8D9;
    color: #fff;
}

.Guarante-privacy{
  font-size: 44px;
  margin-bottom: 50px;
  color: #D0343C;
  text-shadow: 2px 2px 1px #33A8D9;
}
.Guarante-privacy-main .col{
  position: relative;
  top: 0;
  left: 0;
}
.Guarante-privacy-image{
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  
}
.Guarante-privacy-image:hover{
  transform: scale(1.05);
  transition: all .5s ease;
}
.box{
  width: calc(100% - 10px);
  background: #fff;
  box-shadow: 0 21px 32px 0 rgba(236, 6, 6, 0.2);
  height: 180px;
  padding: 30px 20px;
  margin-top: -60px;
  position: relative;
  z-index: 10;
  margin-left: auto;
  margin-right: -20px;
}

.box:hover{
  color: white;
  background-color: #33A8D9;
}


.num{
  color: #D0343C;
  font-size: 50px;
  line-height: 2.5em;
  margin-left: 5px;
}
.scroll-btn{
    color: rgb(191, 8, 8);
    position: fixed;
    bottom: 50px;
    right: 30px;
    font-size: 3rem;
    font-weight: bold;
    

}


.whatapp-icon{
 
  background-color: green;
  color: white;
  position: fixed;
  top: 20%;
  bottom: 50%;
  right: 2%;
  font-size: 4rem;
  border-radius: 25%;

  box-shadow: 1px 3px 20px 10px green;
  animation: zoom-in-zoom-out 1s ease infinite;
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.4, 1.4);
  }
  100% {
    transform: scale(1, 1);
  }
}
/**********************Solution-page ************/

.sol-card{
    width: auto;
    height: 400px;
    background: #33A8D9 ;
    transform: skewY(5deg);
    gap: 50px;
    
 }
 
 .sol-card-text {
    color: #D0343C ;
    transform: skewY(-5deg);
    
  }
.sol-card .card-sol-img{
    display: block;
    width: 100%;
    height: 70%;
}



.sol-overlay {
    
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, #f2f5f7 0%, #33A8D9 20%,#f2f5f7 20%, #33A8D9  20%, #f2f5f7   100%);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: all .8s ease;
  }

  .sol-card:hover .sol-overlay {
    
    padding: 20px;
    height: 100%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
       
  }

  .card-prod:hover .sol-overlay {
    
    padding: 20px;
    height: 100%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
       
  }
  .card-Sol-title{
    color: #D0343C ;
  }
  .sol-card:hover .card-Sol-title{
    visibility: hidden;
  }
  .sol-explore{
    position: absolute;
    top: 74%;
    right: 15%;
    font-size: x-large;
    transform: skewY(-5deg);
  }
  .sol-text {
    text-decoration: none;
    color: black;

  }
  .sol-text:hover {
    color: white;
  }
  .sol-explore:hover{
    color: white;
  }
  .card-Sol-title{
    transform: skewY(-5deg);
  }




/**********************Prodution-page ************/

.Product-card{
  width: auto;
  height :100px ;
 
  
  
}

.Prod-card-text {
  color: white;
  transform: skewY(-5deg);
  
}
.Prod-card .card-Prod-img{
  display: block;
  width: 100%;
  height: 70%;
}



.Prod-overlay {
  
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, #faf7f7 0%, #D0343C  20%,#33A8D9 20%, #33A8D9   20%, #2872AF   100%);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: all .8s ease;
}

.Prod-card:hover .Prod-overlay {
  
  padding: 20px;
  height: 100%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
     
}
.card-Prod-title{
  color: #D0343C ;
  font-weight: bold;
}
.Prod-card:hover .card-Prod-title{
  visibility: hidden;
}
.Prod-explore{
  position: absolute;
  top: 74%;
  right: 15%;
  font-size: x-large;
  transform: skewY(-5deg);
}
.Prod-text {
  text-decoration: none;
  color: black;

}
.Prod-text:hover {
  color: white;
}
.Prod-explore:hover{
  color: white;
}
.card-Prod-title{
  transform: skewY(-5deg);
}




 /****************** Search*****************/
 .search-bar{
    color: #35737f;
    text-align: center;
    font-size: 26px;
    position: relative;
 }
 .search-bar::before{
  content: '|';
  margin-left: 100px;
  margin-right: 30px;
  display: inline-block;
 }
 .search-icon:hover{
  color: #34c5ca;
  transform: scale(1.2);
  transition: all .8s ease;
 }

 

 /************************/

/* Style the overlay */
.search-overlay {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999900000;
  background-color: rgba(0,0,0,0.9);
  top: 0;
  left: 0;
  transition: 0.1s;
  overflow-x: hidden;
  transform: translateY(1);
  transition: all 1000ms ease;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}



/* Style the "Close" button */
.close-button {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #fff;
}

/* Style search elements */
.search-input {
  width: 400px;
  padding: 12px 15px;
  border: none;
  outline: none;
  border-radius: 30px 0px 0px 30px;
}

.search-button {
  padding: 12px 15px;
  border: none;
  cursor: pointer;
  background: #ffc107;
  border-radius: 0px 30px 30px 0px;
  transition: 0.3s;
}

.search-button:hover {
  background: #ff9800;
}

.search-text {
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
}
 /***********************/
 .Clients-img{
  height: 100%;
  
 
 }
 .parter-brand{
  color: #13023c;

 }




/******************warehouse-page*********************/
.richtext {
  word-spacing: normal;
  color: #060000;
  font-size: 16px;
  line-height: 1.8em !important;
  margin-bottom: 20px;
}

.has-sidebar {
  align-items: flex-start;
}
.section{
  padding: 50px;
}
.wms-image{
  width: auto;
  height:100vh;
}
.warehouse-page-head {
  display: flex;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 7vh;
  font-weight:700;
}
.warehouse-page-subhead {
  display: flex;
  position: absolute;
  top: 70%;
  left: 15%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3vh;
}

.Solution-cared-image:hover{
  transform: scale(1.05);
  transition: all .6s ease;

}
.Solution-prod-head{
  color: #D0343C 

}
.solution-head{
  color: #489bee;
}
.solution-head:hover{
  letter-spacing: 1px;
  transition: all .6s ease;
}
.Solution-content{
  font-weight: bold;
  color: #489bee;
  font-size: 1.1rem;
}
.Solution-content:hover{
  color: #D0343C;
}
/***************************Retail-page*******************/
.Retail-page-head {
  display: flex;
  position: absolute;
  top: 40%;
  left: 38%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 7vh;
  font-weight:700;
} 

.Retail-page-subhead {
  display: flex;
  position: absolute;
  top: 50%;
  left: 24%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3vh;
}

/**********************Assettracking*********************/
.Asset-page-head{
  display: flex;
  position: absolute;
  top: 50%;
  left: 38%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 7vh;
  font-weight:700;
} 
.Asset-page-subhead {
  display: flex;
  position: absolute;
  top: 70%;
  left: 25%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3vh;
}
.Asset-image{
  padding-top: 60px ;
}


/************************Library-page*****************/


.Library-page-head {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-90%, -90%);
  color: white;
  font-size: 10vh;
  font-weight:700;
}
.Library-page-subhead{
  position: absolute;
  top: 60%;
  left: 30%;
  
  transform: translate(-90%, -90%);
  color: white;
  font-size: 20px;
  
}



.library-cared-image{
  padding-left: 50px;
  height: 70%;
}
.library-cared-image:hover{
  transform: scale(1.05);
  transition: all .6s ease;
}

/********************Jewellery-page********************/


.Jewellery-page-subhead{
  position: absolute;
  top: 60%;
  left: 30%;
  transform: translate(-90%, -90%);
  color: rgb(18, 1, 1);
  font-size: 20px;
  
}
.Jewellery-cared-image{
  padding-top: 15px;
}
.Jewellery-cared-image:hover{
  transform: scale(1.05);
  transition: all .6s ease;
}
/****************RFID Reader-page********************/

.banner{
  margin-top: 60px;
}
.card-prod-rfid{
max-width: 500px;
max-height: 200px;
}
.download-cat{
  transform:translate(50%, 50%);
  color: #33A8D9 ;
  font-size: larger;
  
}
.download-cat a{
  text-decoration: inherit;
}

.why-bg{
   
    background: url("./Images/why-bg.jpg") no-repeat;
    background-size: cover;
    padding-bottom: 50px;
}

.RFID-card {
  background-color: #34c5ca;
  border-radius: 10%;
  min-height: 240px;
}
.RFID-head{
  color: white;
}
.RFID-subhead{
 color: gray;
}
.RFID-num{
    position: absolute;
  
    color: rgba(255,255,255,0.2);
    font-size: 100px;
    line-height: 1em;
    
}
.RFID-Content{
  color: white;
}
@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
/********************RFID Antenna-page**********************/

.Antenna-page-head {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-90%, -90%);
  color: white;
  font-size: 10vh;
  font-weight:700;
}
.Antenna-page-subhead{
  position: absolute;
  top: 60%;
  left: 50%;
  
  transform: translate(-90%, -90%);
  color: white;
  font-size: 20px;
  
}
.prod-card-image{
 
  position: absolute;
  padding: 20px;
  width: auto;
  height: 60%;
  margin: 10px;
  object-fit:cover;
}