
.navbar{
  
  background-color:white;
}

.shadow-box{
  border-radius: 2px;
  box-shadow: 0px 2px 2px 0 #D0343C;
}

.dropdown .pre-scrollable{
  height: auto;
  max-height: 400px;
  overflow-y: auto;
}
.navbar .navbar-nav .nav-item:hover{
  color:  #33A8D9;
  transform: scale(1.01);
  
  
}
.navbar .navbar-nav .nav-link:hover{
  color: #33A8D9;

}
.navbar  .dropdown-item:hover{
  color: #33A8D9;

}

.navbar .navbar-nav .nav-item{
  position: relative;
  
}
.navbar .navbar-nav .dropdown-item{
  position: relative;
  
}
.navbar .navbar-nav .nav-item::after{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #D0343C;
  width: 0%;
  content: "";
  height: 4px;
  transition: all 0.5s;
}

.navbar .navbar-nav .nav-item .dropdown-item::after{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #D0343C;
  width: 0%;
  content: "";
  height: 4px;
  transition: all 0.5s;
}

.navbar .navbar-nav .nav-item:hover::after {
  width: 100%;
}


.nav-item{
  padding-left: 2px;
  margin-left: 5px;
  letter-spacing: 3px;
  
}

