.card{
    
    max-width: 30rem;
    min-height: 25rem;
}
.about-card{
   background-color: #f4f4f4;
   color: black;
}
.application-card{
  max-width: 30rem;
  min-height: 30rem;
}

.solution-card , .hot-card{
 
  max-width: 20rem;
  min-height: 20rem;
}


@media screen and (min-width:570) {
  .prod-card-images{
    padding: 0%;
    margin: 0%;
  }
}
.solution-image{
  margin-top: 10rem;
}

.card-image{
  padding: 10px;
  height: 80%;
  width: 100%;
  text-align: center;

}


.home-card-title{
  padding: 10px;
  font-size: xx-large;
  color: black;

}

.card-title-overlay{
    margin-top: 1rem;
    word-wrap: break-word;
    padding: 1px;
    font-size: 1.8rem;
    color: black;
    font-weight: bold;

}
.card-title-overlay:hover{
    color: #29a0e4;
    
}


.access-cont-image{
  
  height: 45%;
  width: 45%;
}
.home-prod-card-image{
  height: 45%;
  width: 45%;
}

.Cat-btn{
    box-shadow: inset;
 }

.Cat-btn:hover{
  background:  #D0343C;
  border: 1px solid #33A8D9;
   
  }

.card-prod{
    height: 400px;
    width: auto;
    background-color: #f4f4f4;
}

.card-hot-prod{
  height: 100px;
  
  background-color: #f4f4f4;
}


.card-prod-title{
text-decoration: underline;
text-decoration-color: #D0343C;
text-decoration-thickness:30% ;
}


.prod-image{
    height: 50%;
    display: block;
    position: relative;
    opacity: .2
}
.prod-head{
  padding-right: 10px;
  text-align: center;
}

.app-cont{
margin: 0 0 20px;
padding-left: 240px;
padding-right: 240px;
}

.cat-home-prod{
  word-wrap: break-word;
  color: #34c5ca;
}

.card-app-body{
background-color: #33A8D9;
}

.card-app-body:hover{
    background-color:#D0343C ;
 }

.card-img-top:hover{
transform: scale(1.02);
 }
 .card-img-top{
  height: 14rem;
 }
 



 .card-app-title{
color: white;
text-align: center;
font-weight: bold;

}
.card-app-title:hover{
    text-decoration: underline;
}


.card-app-icon{
    padding-top:20px; 
    text-align: center;
    color: white;
    font-weight: bold;
  
}

.card-app-icon1{
    padding-bottom:20px; 
    text-align: center;
    color: white;
    font-weight: bold;
    transform: rotate(180deg);
}

.app-more-btn {
background: white;
color: #33A8D9;
transition: width .5s;
transition-timing-function: linear;

}
.app-more-btn:hover{
    width: 220Px;
}
.app-btn {
    background-color: white;
    color: #D0343C;
    font-size: larger;
    font-weight: bold;
    
    
}
.app-btn:hover{
    
    background-color: transparent;
}
.card-app-text{
  color: white; 
  opacity: .9; 
}
  
.card .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:#33A8D9 ;
    overflow: hidden;
    width: 100%;
    height: 20%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .8s ease;
    transition: .8s ease;
  }
  
  .home-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate(50%,50%);
    background-color: rgba(234, 234, 234, 0.5);;
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .8s ease;
    transition: .8s ease;
  }


  .hot-card .card-body .home-overlay-hot {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(234, 234, 234, 0.5);;
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .8s ease;
    transition: .8s ease;
  }

.card-body:hover .overlay {
    padding: 10px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);    
  }

  .card-body:hover .home-overlay {
    padding: 1px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);    
  }

  .card-body:hover .home-card-title {
    visibility: hidden;
  }
  .card-body:hover .view-more{
    visibility: hidden;
  }
  
.col ul{
    list-style: none;
}
.col ul li a{
    text-decoration: none;
    text-decoration-color: black;
    color: black;
}
.col ul li a:hover{
    text-decoration: none;
    color: #33A8D9;
}
.view-more-overlay a{
    text-decoration: none;
}
li{
  text-align: justify;
}
.card-text {
    color: black;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    margin-top: 5px; 
}
.card-product-text {
  color: black;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  margin-top: 5px; 
}


.card-product-text span{
  color: rgb(0, 72, 255);
  font-size: 15px;
  text-align: left;
  margin-top: 15px; 
}
  .card-prod-text{
    word-wrap: break-word;
    top: 20%;
    bottom: 50%;
    position: absolute;
    color: black;
    font-weight: bold;
    font-size: 1rem;

  
    text-align: center;
  }
  .view-more{
    color: #ac0606;
    font-weight: bold;

  }
  .view-more-overlay ,a {
    color: #ac0606;
    font-weight: bold;

  }
  view-more-overlay ,a:hover {
    color: #ac0606;
    font-weight: bold;

  }